"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Platform = exports.CostModifier = exports.PrintingMethod = exports.CUSTOM_CALCULATOR = exports.CUSTOM_SKU = void 0;
exports.CUSTOM_SKU = "custom";
exports.CUSTOM_CALCULATOR = "custom";
var PrintingMethod;
(function (PrintingMethod) {
    PrintingMethod["Litho"] = "litho";
    PrintingMethod["Digital"] = "digital";
    PrintingMethod["Wide"] = "wide";
})(PrintingMethod || (exports.PrintingMethod = PrintingMethod = {}));
var CostModifier;
(function (CostModifier) {
    CostModifier["PERCENT"] = "percent";
    CostModifier["AMOUNT"] = "amount";
})(CostModifier || (exports.CostModifier = CostModifier = {}));
var Platform;
(function (Platform) {
    Platform["SiteFlow"] = "SiteFlow";
    Platform["AutomationEngine"] = "AutomationEngine";
})(Platform || (exports.Platform = Platform = {}));
